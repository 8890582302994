<template>
    <div>
        <input type="search" placeholder="Search for a player" name="player-search" v-model="searchTerm" class="mb-4 text-xs placeholder-gray-600 w-full bg-blue-700  border rounded-lg py-4 pl-2 w-24 leading-tight border-gray-800 focus:outline-none focus:border-blue-500 text-white">
        <div v-if="searchTerm.length > 0">
            <div v-for="(user, index) in results" :key="user.username" class="flex justify-between mb-2">
                <div>
                    <img :src="user.storage_avatar" alt="" class="h-8 w-8 rounded-full inline-block mr-1"> <span class=" font-bold text-xs">{{ user.username }}</span>
                </div>
                <div>
                    <button @click="invitePlayer(user, index)" class="btn-gradient-blue text-xxs py-1 px-4 rounded-lg font-bold">Invite</button>
                </div>
            </div>
        </div>
        <Flash v-if="flashMessage" :message="flashMessage.message" :level="flashMessage.level"></Flash>
    </div>
</template>

<script>

import Flash from "./Flash.vue";
import axios from "axios";

export default {
    components: {Flash},
    props: ['team'],
    data() {
        return {
            results: [],
            searchTerm: '',
            flashMessage: null
        }
    },
    watch: {
        searchTerm(after, before) {
            this.fetchPlayers();
        },
    },

    methods: {
        fetchPlayers() {
            if (this.searchTerm.length > 0)
            {
                axios.get('/api/search/users', {params: { term: this.searchTerm }})
                    .then(response => this.results = response.data.data)
                    .catch(error => {
                        this.flashMessage = {message: 'Error fetching players', level: 'error'};
                    });
            }
        },

        invitePlayer(user, index) {
            axios.post(route('team.invites.store', this.team.id), {
                user_id: user.id
            }).then(response => {
                if (index > -1) {
                    this.results.splice(index, 1)
                }
                this.flashMessage = {message: response.data.message, level: response.data.level};
            }).catch(error => {
                this.flashMessage = {message: error.response.data.message, level: error.response.data.level};
            })
        }

    }
}
</script>
