<template>
    <div class="relative inline-block text-left" v-if="getPlatforms.length > 0">
        <div>
            <span class="rounded-md shadow-sm">
                <button type="button"
                        @click="isOpen = !isOpen"
                        class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out bg-blue-800 border border-gray-800 rounded-md hover:text-white focus:outline-none active:bg-gray-50"
                        id="options-menu" aria-haspopup="true" aria-expanded="true">
                    <i :class="getIcon(game.active_platform)" />

                    {{ game.active_platform.name }}

                    <svg class="w-5 h-5 ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"/>
                    </svg>
                </button>
            </span>
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out transform"
            enter-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in transform"
            leave-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <div v-show="isOpen" class="absolute w-56 mt-2 origin-top-right rounded-md shadow-lg lg:right-0">
                <div class="bg-blue-600 rounded-md shadow-xs">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a :href="getUrl(platform)"
                           v-for="platform in getPlatforms"
                           class="block px-4 py-2 text-sm leading-5 text-gray-200 bg-blue-600 border-gray-800 hover:text-white focus:outline-none"
                           role="menuitem">
                            <i :class="getIcon(platform)" />
                            {{ platform.name }}
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'game-platform-filter',
        props: ['game'],
        data: () => ({
            isOpen: false,
        }),
        methods: {
            getUrl: function (platform) {
                return `/game/${platform.slug}/${this.game.slug}`
            },
            getIcon: function (platform) {
                return `${platform.icon} mr-2 mt-1`
            },
        },
        computed: {
            getPlatforms: function () {
                return this.game.platforms.reduce((results, platform) => {
                    if (platform.id !== this.game.active_platform.id) {
                        results.push({
                            slug: platform.slug,
                            name: platform.name,
                            icon: platform.icon,
                        })
                    }
                    return results
                }, []);
            }
        }
    }
</script>
