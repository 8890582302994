<template>
<div class="bg-gray-900 text-white border rounded border-gray-800" >
    <div id="header" class="flex justify-between text-xs px-6 items-center py-4">
        <h2 class="font-semibold">Chat</h2>
    </div>
    <div id="body" class="bg-blue-800 text-white px-4 py-4" style="overflow-y: scroll;max-height: 350px; min-height:350px;">
        <div v-for="message in messages" :key="message.id" id="chat-item" class="flex mb-3">
            <img :src="message.user.storage_avatar" class="h-8 w-8 rounded-full mr-2" alt="">
            <div class="text-white">
                <div class="font-semibold text-xs">{{ message.user.username }} <span style="font-size:0.5rem" class=" text-gray-600 mb-1">{{ message.created_at }}</span></div>
                <div class="py-2 bg-gray-700 rounded text-xs px-2">{{ message.message }}</div>
            </div>
        </div>
    </div>
    <div id="footer" class="bg-blue-900 border-t border-blue-600 text-white px-6 py-2 relative">
        <input  @keyup.enter="sendMessage" v-model="message" type="text" class="w-full bg-blue-700 text-xxs py-3 px-2 rounded-lg text-white font-semibold" placeholder="Type a message here...">
        <a href="#" @click.prevent="sendMessage" class="absolute w-6 h-6 rounded text-blue-500 text-sm items-center justify-center flex" style="right:35px;top:15px;"><i class="fas fa-paper-plane text-2xl bg-none mt-2"></i></a>
    </div>
</div>
</template>
<script>
export default {
    props: ['match'],
    data() {
        return {
            messages: [],
            message: '',
            sendingMessage: false
        }
    },
    mounted() {
        axios.get('/api/tournaments/matches/' + this.match + '/messages').then((resp) => {
            this.messages = resp.data.data;
        })
        let container = this.$el.querySelector("#body");
        container.scrollTop = container.scrollHeight;
    },

    updated: () => { let el = document.getElementById('body'); el.scrollTop = el.scrollHeight; },

        methods: {
        sendMessage()
        {
            if(this.message.length > 0)
            {
                this.sendingMessage = true;
                axios.post('/api/tournaments/matches/' + this.match + '/messages', {
                    message: this.message
                }).then((resp) => {
                    this.messages.push(resp.data.data);
                    this.message = '';
                    this.sendingMessage = false;
                });
            }
        }
    }
}
</script>
