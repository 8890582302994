<template>
    <div class="alert alert-flash"
         :class="'alert-'+level"
         role="alert"
         v-show="show"
         v-text="message">
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps({
    message: String,
    level: {
        type: String,
        default: 'success'
    }
});

const show = ref(false);

onMounted(() => {
    if (props.message) {
        flash(props.message, props.level);
    }
});

watch(() => props.message, (newMessage) => {
    if (newMessage) {
        flash(newMessage, props.level);
    }
});

const flash = (message, level) => {
    show.value = true;
    setTimeout(() => {
        show.value = false;
    }, 5000);
};
</script>

<style scoped>
.alert-flash {
    position: fixed;
    right: 25px;
    bottom: 45px;
    width: 20%;
    z-index: 99;
}
.alert-error {
    background: red;
    padding: 8px 8px;
    color: #fff;
}
.alert-success {
    background: green;
    padding: 8px 8px;
    color: #fff;
}
</style>
