<template>
    <modal name="league-team-selection-modal" classes="bg-blue-700 rounded-lg border-gray-800 border text-white w-2/12 sm:w-9/12" height="auto" adaptive="true">
        <div class="py-6 px-12">
            <div class="text-center mb-4">
                <div class="text-lg font-bold uppercase">
                    Select Team
                </div>
                <div class="text-xs text-gray-400">
                    Setup your team to enter the battlefield
                </div>
            </div>
            <form @submit.prevent="addTeamToLeague">
                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                    <select  @change="teamSelectionChanged($event)" v-model="form.team"  name="team_id" class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                        <option v-for="team in teams" :key="team.id" :value="team.id" selected="selected" class="text-white py-2">{{ team.name }}</option>
                    </select>
                </div>

                <div v-if="teamHasBeenSelected">
                    <div class="text-center mb-4 mt-4">
                        <div class="text-lg font-bold uppercase">
                            Roster Selection
                        </div>
                        <div class="text-xs text-gray-400">
                            Select the players to enter for this tournament
                        </div>
                    </div>
                    <div v-if="!loadingRoster" class="flex justify-between mb-1 items-center" >
                        <div class="w-full" v-for="member in selectedTeam.members" :key="member.id">
                            <div class="flex py-3 px-3  items-center rounded-lg bg-blue-900 mb-1 w-full">
                                <div>
                                    <div>
                                        <label class="text-gray-500 font-bold">
                                            <input :id="member.username" :value="member.id" v-model="form.selectedRoster" class="mr-2 leading-tight" type="checkbox">
                                        </label>
                                    </div>
                                </div>
                                <img :src="member.storage_avatar" class="mr-2 rounded-lg w-8 h-8">
                                <div class="leading-none">
                                    <div class="font-semibold text-xs">{{ member.username }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        Loading your roster...
                    </div>
                </div>
                <div class="mt-6">
                    <button v-html="formSubmitting ? spinnerHtml : 'Lets Go'" :disabled="(!teamSelected() || formSubmitting)" :class="{'opacity-50 cursor-not-allowed': !teamSelected() || formSubmitting}" type="submit" class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">

                    </button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import userTeamsService from "../../store/api/userteams"
import api from './../../store/api/all'
import {mapGetters} from "vuex";
export default {
    name: "LeagueRegisterTeamModal",
    data() {
        return {
            teams: [],
            spinnerHtml: '<i class="fas fa-spinner fa-spin">',
            loadingTeams: false,
            selectedTeam: null,
            loadingRoster: false,
            teamHasBeenSelected: false,
            formSubmitting: false,
            form: new Form({
                league_id: null,
                selectedRoster: [],
                team_id: null,
            })
        }
    },
    mounted() {
        this.loadingTeams = true;
        userTeamsService.getUserTeams().then((response) => {
            this.teams = response.data.data;
        });

        this.loadingTeams = false;
    },
    computed: {
        ...mapGetters({
            league: 'league',
            loading: 'loadingLeague'
        })
    },
    methods: {
        teamSelected() {
            return this.selectedTeam != null;
        },
        addTeamToLeague() {
            if (this.teamSelected()) {
                this.formSubmitting = true;
                axios.post('/api/leagues/' + this.league.id + '/join', this.form.data())
                .then(({data}) => {
                    flash(data.message, 'success');
                    setTimeout(() => {
                        this.$modal.hide('league-team-selection-modal')
                    }, 1500)
                }).catch(error => {
                    let firstError = Object.keys(error.response.data.errors)[0];
                    flash(error.response.data.errors[firstError][0], 'error')
                    this.formSubmitting = false;
                })
            }
        },
        teamSelectionChanged(event) {
            this.form.league_id = this.league.id
            this.teamHasBeenSelected = true;
            this.loadingRoster = true;
            this.form.selectedRoster = [];
            this.form.team_id = this.form.team;
            api.getTeam(this.form.team).then((resp) => {
                this.selectedTeam = resp.data.data
                this.loadingRoster = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
