import conversation from "../modules/conversation";

export default {
    getConversation(id) {
        return new Promise((resolve, reject) => {
            axios.get('/api/conversations/' + id).then((response) => {
                resolve(response);
            })
        })
    },

    getConversations(page) {
        return new Promise((resolve, reject) => {
            axios.get('/api/conversations?page=' + page).then((response) => {
                resolve(response);
            });
        })
    },

    storeConversationReply(id, {body}) {
        return new Promise((resolve, reject) => {
            axios.post('/api/conversations/' + id + '/reply', {
                body: body
            }).then((response) => {
                resolve(response);
            });
        })
    },

    getTeam(id) {
        return new Promise((resolve, reject) => {
            axios.get('/api/teams?teamId=' + id).then((response) => {
                resolve(response);
            })
        })
    }
} 