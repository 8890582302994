<template>
    <div class="w-full">
        <div class="border-b border-gray-800 tabs tabs-background-color">
            <ul class="flex">
                <li
                    v-for="tab in tabs"
                    :key="`tab-headers-${tab.props.name}`"
                    class="mr-4"
                    @click="onSelectTab(tab);"
                >
                    <Link
                        replace
                        preserve-state
                        preserve-scroll
                        class="font-semibold px-3 py-4 inline-block"
                        :class="{ 'text-gray-600': tab.props.name !== selectedTab, 'border-blue-500 border-b-2': tab.props.name === selectedTab  }"
                        :href="tab.props.href"
                    >
                        <i v-if="tab.props.icon" :class="tab.props.icon"></i> {{ tab.props.name }}
                    </Link>
                </li>
            </ul>
        </div>
        <div class="tabs-details">
            <component
                v-for="tab in tabs"
                :is="tab"
                v-show="selectedTab === tab.props.name"
                :key="`tab-body-${tab.props.name}`"
                :class="{ 'hidden': selectedTab !== tab.props.name }"
            >
            </component>
        </div>
    </div>
</template>


<script setup>

import { Link } from "@inertiajs/vue3";
import { onBeforeMount, ref, useSlots, Comment } from "vue";

const hash = window.location.hash;

const slots = useSlots();
const tabs = ref([]);
const selectedTab = ref('');

onBeforeMount(() => {
    const allTabs = slots.default();

    tabs.value = allTabs.filter(tab => tab && tab.type && tab.type !== Comment);

    if (tabs.value.length > 0) {
        const defaultTab = tabs.value.find(tab => tab.props.href === hash);
        selectedTab.value = defaultTab ? defaultTab.props.name : tabs.value[0].props.name;
    } else {
        selectedTab.value = '';
    }
});

const onSelectTab = (s) => {
    if (s?.props?.name) {
        selectedTab.value = s.props.name;
    }
};

/*export default {
    data() {
        return {
            tabs: [],
            selectedTab: null
        };
    },

    created() {
        // this.tabs = this.$children;
        // this.tabs = this.$slots.default().filter(child => child.type.name === 'Tab')
        this.tabs = this.$slots.default();
        this.selectedTab = this.tabs[0];
        console.log("tabs", this.tabs);
        console.log("selectedTab", this.selectedTab);
    },

    methods: {
        selectTab(selectedTab) {
            // this.tabs.forEach(tab => {
            //     tab.props.isActive = (tab.props.href === selectedTab.props.href);
            // });
            this.selectedTab = selectedTab;
        }
    }
}*/
</script>

<style scoped>
li {
    cursor: pointer;
}

.fade-transition {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave {
    opacity: 0;
}

</style>