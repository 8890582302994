<template>
    <div class="mt-2 flex flex-wrap">
        <a v-for="user in usersInvited" :key="user.username" :href="route('user.show', user.username)" class="mb-1"><img :title="user.username" :alt="user.username" :src="user.storage_avatar" class="h-10 w-10 mr-2 rounded-full"/></a>
    </div>
</template>
<script>
export default {
    props: ['invitedUsers'],
    data() {
        return {
            usersInvited: this.invitedUsers
        }
    },
}
</script>
