<template>

    <VueFinalModal
        name="tournament-team-selection-modal"
        classes="bg-blue-700 rounded-lg border-gray-800 border text-white"
        class="flex justify-center items-center"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 rounded-lg space-y-2  bg-blue-600 text-white"
        height="auto"
        adaptive="true"
    >
        <div  v-if="Object.keys(form.errors).length > 0" class="px-12">
            <div class="bg-red-500 border-t-4 border-red-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2 w-6/10" role="alert">
                <div class="flex">
                    <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                    <div>
                        <p class="font-bold">Error</p>
                        <div
                            v-for="fieldErrs in form.errors"
                            :key="fieldErrs"
                        >
                            <p
                                v-for="err in fieldErrs"
                                :key="err"
                                class="text-sm"
                                v-text="err"
                            >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-6 px-12">
            <div class="text-center mb-4">
                <div class="text-lg font-bold uppercase">
                    Select Team
                </div>
                <div class="text-xs text-gray-400">
                    Setup your team to enter the battlefield
                </div>
            </div>
            <form @submit.prevent="addTeamToTournament">
                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                    <select
                        @change="teamSelectionChanged($event)"
                        v-model="form.team"
                        name="team_id"
                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-gray-400 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none"
                    >
                        <option
                            v-for="(team, index) in teams"
                            :key="team.id"
                            :value="team.id"
                            :selected="index === 0"
                            class="text-gray-400 py-2"
                        >
                            {{ team.name }}
                        </option>
                    </select>
                </div>

                <div v-if="selectedTeam" style="max-height:360px;overflow-y:auto">
                    <div class="text-center mb-4 mt-4">
                        <div class="text-lg font-bold uppercase">
                            Roster Selection
                        </div>
                        <div class="text-xs text-gray-400">
                            Select the players to enter for this tournament
                        </div>
                    </div>
                    <div class="flex justify-between mb-1 items-center" v-for="member in selectedTeam.members" :key="member.id">
                        <div class="flex py-3 px-3  items-center rounded-lg bg-blue-900 mb-1 w-full">
                            <div>
                                <div>
                                    <label class="text-gray-500 font-bold">
                                        <input :id="member.username" :value="member.id" v-model="form.selectedRoster" class="mr-2 leading-tight" type="checkbox">
                                    </label>
                                </div>
                            </div>
                            <img :src="member.storage_avatar" class="mr-2 rounded-lg w-8 h-8">
                            <div class="leading-none">
                                <div class="font-semibold text-xs">{{ member.username }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6">
                    <button :disabled="!teamSelected()" :class="{'opacity-50 cursor-not-allowed': !teamSelected()}" type="submit" class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                        Lets Go
                    </button>
                </div>
            </form>
        </div>
    </VueFinalModal>
</template>

<script setup>
import api from './../store/api/all'
import {VueFinalModal} from "vue-final-modal";
import {reactive, ref} from "vue";

const props = defineProps(['teams', 'tournament']);

const selectedTeam = ref(null);

// const form = useForm({
// });

const form = reactive({
    team: null,
    team_id: null,
    selectedRoster: [],
    tournament_id: props.tournament.id,
    errors: {}
});

const teamSelected = () => {
    return selectedTeam.value != null;
}

const addTeamToTournament = () => {
    if (teamSelected()) {

        axios.post('/api/tournaments/' + props.tournament.slug + '/join', form, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((resp) => {
            window.location.href = "/tournaments/" + props.tournament.slug;
        }).catch((err) => {
            form.errors = err.response.data.errors;
        });


        // {
        //
        //     onSuccess: (res) => {
                // window.location.href = "/tournaments/" + props.tournament.slug;
            // },
            //     onError: (res) => {
            // console.error(res)
        // }
        // }
        // window.location.href = "/tournaments/" + props.tournament.slug;
        // form.submit('post', '/api/tournaments/' + this.tournament.slug + '/join').then((resp) => {
        //     window.location.href = "/tournaments/" + this.tournament.slug;
        // }).catch((err) => {
        //     console.error(err)
        // });
    }
}

const teamSelectionChanged = (event) => {
    form.selectedRoster = [];
    form.team_id = form.team;
    api.getTeam(form.team).then((resp) => {
        selectedTeam.value = resp.data.data
    });
}

/*export default {
    components: {VueFinalModal},
    props: ['teams', 'tournament'],
    // data() {
    //     return {
    //         selectedTeam: null,
    //         form: new Form({
    //             tournament_id: null,
    //             team: null,
    //             team_id: null,
    //             selectedRoster: [],
    //         })
    //     }
    // },
    mounted() {
        this.form.tournament_id = this.tournament.id;
    },
    methods: {
        teamSelected() {
            return this.selectedTeam != null;
        },
        addTeamToTournament() {
            if (this.teamSelected()) {
                this.form.submit('post', '/api/tournaments/' + this.tournament.slug + '/join').then((resp) => {
                    window.location.href = "/tournaments/" + this.tournament.slug;
                }).catch((err) => {
                });
            }
        },
        teamSelectionChanged(event)
        {
            this.form.selectedRoster = [];
            this.form.team_id = this.form.team;
            api.getTeam(this.form.team).then((resp) => {
                this.selectedTeam = resp.data.data
            });
        }
    }
}*/
</script>
