<template>
    <a @click.prevent="showModal" href="#" class="inline-flex">
        <div class="btn-gradient-blue bg-blue-500 px-6 py-3 rounded-l-lg font-medium text-xs font-semibold relative">
            <i class="fas fa-check-circle font-semibold"></i> Register

            <div class="triangle-3 absolute right-0">
            </div>
        </div>
        <div class="bg-blue-500 px-5 py-3 rounded-r-lg font-medium text-xs font-semibold z-1">
            {{  price }} Creds
        </div>
    </a>
</template>
<script setup>

import {useModal} from "vue-final-modal";
import TournamentTeamSelectionModal from "./TournamentTeamSelectionModal.vue";

const props = defineProps(['price', 'teams', 'tournament']);

const { open, close } = useModal({
    component: TournamentTeamSelectionModal,
    attrs: {
        title: "Select Team",
        onConfirm() {
            close();
        },
        teams: props.teams,
        tournament: props.tournament
    },
    slots: {
        default: 'Select the players to enter for this tournament'
    }
})

const showModal = function() {
    if (Object.keys(props.teams).length) {
        open();
    } else {
        window.location.href = `/teams/create?game=${props.tournament.game.slug}&redirect=${window.location.pathname}`;
    }

    /*if (Object.keys(this.teams).length) {
        this.$modal.show('tournament-team-selection-modal')
    } else {
        window.location.href = `/teams/create?game=${this.tournament.game.slug}&redirect=${window.location.pathname}`;
        // this.$modal.show('team-create-modal');
    }*/
}
</script>
